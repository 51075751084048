<template>
  <div class="pre-order">
    <KTCodePreview v-bind:title="title">
      <template v-slot:toolbar>
        <div class="row">
          <b-dropdown size="sm" right lazy>
            <template slot="button-content">
              <i style="font-size: 1rem" class="fas fa-cog"></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="downloadExcel">
              <span>
                <i style="font-size: 1rem" class="far fa-file-excel"></i>
                &nbsp; Xuất Excel
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <b-col>
            <multiselect
              :value="apiParams.storeIds"
              :options="filteredStore"
              :multiple="true"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="true"
              @select="onSelected($event, 'Store')"
              @remove="onRemoved($event, 'Store')"
              @search-change="onInputed($event, 'Store')"
              :searchable="true"
              :show-labels="false"
              :showPointer="false"
              placeholder="Chọn cửa hàng"
              :block-keys="['Tab', 'Enter']"
              :internal-search="false"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{
                    values.map((item) => item.id).includes(-1)
                      ? values.length - 1
                      : values.length
                  }}
                  cửa hàng đã chọn</span
                >
              </template>
              <span slot="option" slot-scope="scope">
                <div class="multiselect__checkbox_name">
                  {{ scope.option.name }}
                  <input
                    class="multiselect__checkbox"
                    type="checkbox"
                    v-model="scope.option.checked"
                  />
                </div>
              </span>
              <span style="font-size: 12px" slot="noResult"
                >Không có kết quả</span
              >
            </multiselect>
          </b-col>
          <b-col>
            <div class="d-flex">
              <b-input-group>
                <date-picker
                  placeholder="Từ"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.fromDate"
                ></date-picker>
              </b-input-group>
              <span class="ml-1 mr-1"></span>
              <b-input-group>
                <date-picker
                  placeholder="Đến"
                  class="form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="apiParams.toDate"
                ></date-picker>
              </b-input-group>
            </div>
          </b-col>

          <b-col>
            <multiselect
              :value="apiParams.formIds"
              :options="filteredForm"
              :multiple="true"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="true"
              @select="onSelected($event, 'Form')"
              @remove="onRemoved($event, 'Form')"
              @search-change="onInputed($event, 'Form')"
              :searchable="true"
              :show-labels="false"
              :showPointer="false"
              :preselect-first="false"
              placeholder="Chọn chương trình"
              :block-keys="['Tab', 'Enter']"
              :internal-search="false"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{
                    values.map((item) => item.id).includes(-1)
                      ? values.length - 1
                      : values.length
                  }}
                  chương trình đã chọn</span
                >
              </template>
              <span slot="option" slot-scope="scope" class="text-wrap">
                <div class="multiselect__checkbox_name">
                  {{ scope.option.name }}
                  <input
                    class="multiselect__checkbox"
                    type="checkbox"
                    v-model="scope.option.checked"
                  />
                </div>
              </span>
              <span slot="noResult" style="font-size: 12px"
                >Không có kết quả</span
              >
            </multiselect>
          </b-col>
          <b-col>
            <multiselect
              :value="apiParams.status"
              :options="filteredStatus"
              :multiple="true"
              track-by="id"
              :close-on-select="false"
              :clear-on-select="true"
              @select="onSelected($event, 'Status')"
              @remove="onRemoved($event, 'Status')"
              @search-change="onInputed($event, 'Status')"
              :searchable="true"
              :show-labels="false"
              :showPointer="false"
              :preselect-first="false"
              placeholder="Chọn trạng thái"
              :block-keys="['Tab', 'Enter']"
              :internal-search="false"
            >
              <template slot="tag">{{ '' }}</template>
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{
                    values.map((item) => item.id).includes(-1)
                      ? values.length - 1
                      : values.length
                  }}
                  trạng thái đã chọn</span
                >
              </template>
              <span slot="option" slot-scope="scope">
                <div class="multiselect__checkbox_name">
                  {{ scope.option.name }}
                  <input
                    class="multiselect__checkbox"
                    type="checkbox"
                    v-model="scope.option.checked"
                  />
                </div>
              </span>
              <span slot="noResult" style="font-size: 12px"
                >Không có kết quả</span
              >
            </multiselect>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="apiParams.partnerPaymentStatus"
              :options="paymentStatus"
              class="select-style"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Id đơn hàng"
              v-model="apiParams.orderId"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <vue-autosuggest
              :suggestions="optionEmployees"
              :get-suggestion-value="(show) => show.item.fullName"
              :limit="100"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Nhập nhân viên CSKH',
              }"
              :should-render-suggestions="
                (size, loading) =>
                  size >= 0 && !loading && apiParams.createdByName
              "
              v-model="apiParams.createdByName"
              @input="onInputed($event, 'Employee')"
              @selected="onSelected($event, 'Employee')"
            >
              <template slot-scope="{ suggestion }">
                {{ suggestion.item.fullName }}
              </template>
            </vue-autosuggest>
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Tên hoặc SĐT khách hàng"
              v-model="apiParams.customerSearch"
              v-on:keyup.enter="onFilter()"
              v-focus
            />
          </b-col>
          <b-col>
            <b-input
              size="sm"
              placeholder="Tìm kiếm theo sản phẩm"
              v-model="apiParams.productSearch"
              v-on:keyup.enter="onFilter()"
            />
          </b-col>

          <b-col>
            <b-input
              size="sm"
              placeholder="Ghi chú"
              v-model="apiParams.customerAddress"
              v-on:keyup.enter="onFilter()"
            />
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-input
              size="sm"
              placeholder="Id đơn đặt trước"
              v-model="apiParams.id"
              v-on:keyup.enter="onFilter()"
              autocomplete="off"
            />
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="apiParams.wantUpgrade"
              :options="optionsWantUpgrade"
              class="select-style"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="1">
            <b-button
              size="sm"
              variant="primary"
              style="fontweight: 600; width: 70px"
              @click="onFilter()"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="preOrders"
            :fields="preOrdersFields"
            :small="true"
            :busy="onLoading"
            responsive
            bordered
            hover
          >
            <template #cell(createdAt)="data">
              <span class="d-block mb-2">{{ data.item.createdAt }}</span>
              <span class="d-block">{{ data.item.createdByName }}</span>
              <b class="text-left pull-left" style="font-size: 0.8rem">{{
                data.item.storeName
              }}</b>
            </template>
            <template #cell(id)="data">
              <span
                @click="editPreOrder(data.item.id)"
                class="
                  text-primary
                  cursor-pointer
                  d-block
                  mb-1
                  font-weight-bolder
                "
                >{{ data.item.id }}</span
              >
              <span
                v-b-tooltip.hover
                title="Thanh toán thành công"
                v-if="data.item.partnerPaymentStatus === 1"
              >
                <i
                  class="fa fa-check-circle text-success"
                  aria-hidden="true"
                ></i>
              </span>
              <span
                v-b-tooltip.hover
                title="Thanh toán thất bại"
                v-if="data.item.partnerPaymentStatus === 2"
              >
                <i
                  class="fa fa-times-circle text-danger"
                  aria-hidden="true"
                ></i>
              </span>
            </template>
            <template #cell(formId)="data">
              <b
                class="text-primary cursor-pointer"
                @click="filterByFormId(data.item.formId)"
              >
                {{ data.item.formName }}
              </b>
            </template>
            <template #cell(productName)="data">
              <span v-text="formatProductName(data.item)"></span>
              <div class="mt-2 d-table" v-if="data.item.product">
                <div class="d-table-row">
                  <div class="d-table-cell pl-3 pr-2">
                    <i class="fas fa-level-up-alt fa-rotate-90 pl-1"></i>
                  </div>
                  <div class="d-table-cell">
                    <span v-if="data.item.product.barCode">
                      {{ data.item.product.barCode }}
                    </span>
                    <span class="d-block" v-if="data.item.product.productCode"
                      >({{ data.item.product.productCode }})</span
                    >
                    <span class="d-block font-weight-bolder">
                      {{ data.item.product.productName }}
                    </span>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <span
                v-text="getStatusName(data.item.status)"
                style="width: max-content"
                v-bind:class="{
                  'badge badge-warning text-white': data.item.status === 1,
                  'badge badge-primary text-white': data.item.status === 2,
                  'badge badge-danger': data.item.status === 3,
                  'badge badge-light text-dark': data.item.status === 4,
                  'badge badge-info': data.item.status === 5,
                  'badge badge-light': data.item.status === 6,
                  'badge badge-dark':
                    data.item.status === 7 ||
                    data.item.status === 8 ||
                    data.item.status === 10 ||
                    data.item.status === 11,
                  'badge badge-success': data.item.status === 9,
                }"
              ></span>
              <div class="mt-2">
                <router-link
                  :to="{
                    name: 'edit-order',
                    params: { id: data.item.orderId },
                  }"
                  class="font-weight-bolder"
                  v-b-tooltip.hover.left
                  title="Đơn hàng"
                >
                  {{ data.item.orderId }}
                </router-link>
                <p v-if="data.item.orderWarning" class="mt-2">
                  <i
                    class="fa fa-exclamation-triangle text-warning"
                    v-b-tooltip.hover.bottom
                    :title="data.item.orderWarningName"
                    aria-hidden="true"
                  ></i>
                </p>
              </div>
            </template>
            <template #cell(customerName)="data">
              <b>{{ data.item.customerName }}</b>
              <span
                class="cursor-pointer d-block mb-1"
                @click="editUser(data.item)"
              >
                {{ data.item.customerMobile }}
              </span>
              <div
                v-if="
                  data.item.customerAddress &&
                  data.item.customerAddress.trim().length > 0
                "
                class="mb-1"
              >
                <b>ĐC:</b>
                {{ data.item.customerAddress }}
              </div>
            </template>
            <template #cell(notes)="data">
              <span
                v-if="
                  data.item.installMoneyCode &&
                  data.item.installMoneyCode.trim().length > 0
                "
                class="d-block"
              >
                <b class="d-block">Mã thanh toán:</b>
                {{ data.item.installMoneyCode }}
              </span>
              <p
                v-if="
                  data.item.installMoneyCode &&
                  data.item.installMoneyCode.trim().length > 0
                "
                class="dashed"
              ></p>
              <span class="pre-line">{{ data.item.notes }}</span>
            </template>
            <template #cell(actions)="data">
              <b-dropdown size="sm" no-caret right lazy>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-settings pr-0"
                  ></i>
                </template>
                <b-dropdown-item @click="editPreOrder(data.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchPreOrders"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <EditPreOrder
      :params="preOrder"
      :stores="optionStores"
      v-on:onRefresh="onRefresh"
    />
    <PreOrderExcel :param="apiParams" />
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
import moment from 'moment';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import {
  makeToastFaile,
  formatDate,
  convertPrice,
  removeAccents,
  removeSelectAllOptions,
  selectAllOptions,
} from '@/utils/common';
import {
  PREORDER_STATUS,
  TIME_OUT,
  PAGE,
  PAYMENT_STATUS,
  TIME_TRIGGER,
  CONSTANT_WANT_UPGRADE,
} from '@/utils/constants';
import EditPreOrder from '../../components/pre-order/EditPreOrder';
import { cloneDeep, keyBy, debounce, map, find, assign } from 'lodash';

import PreOrderExcel from '../../components/pre-order/PreOrderExcel';
export default {
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      apiParams: {
        id: null,
        formIds: [],
        // formName: [],
        customerSearch: null,
        productSearch: null,
        status: [],
        notes: null,
        storeIds: [],
        fromDate: null,
        toDate: null,
        partnerPaymentStatus: null,
        createdBy: null,
        orderId: null,
        wantUpgrade: null,
      },
      title: '',
      preOrders: [],
      onLoading: false,
      numberOfPage: 1,
      totalItem: 0,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      preOrdersFields: [
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'id',
          label: 'ID',
          tdClass: 'text-center',
          thStyle: {
            width: '9%',
          },
        },
        {
          key: 'customerName',
          label: 'Khách hàng',
          thStyle: {
            width: '13%',
          },
          tdClass: 'text-wrap',
        },
        {
          key: 'formId',
          label: 'Chương trình',
        },
        {
          key: 'productName',
          label: 'Máy khách muốn sở hữu',
        },
        {
          key: 'notes',
          label: 'Ghi chú',
          thStyle: {
            width: '15%',
          },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: ' ',
          tdClass: 'text-center',
          thStyle: {
            width: '5%',
          },
        },
      ],
      preOrder: {},
      optionForms: [],
      filteredEmployees: [],
      paymentStatus: [],
      optionsWantUpgrade: [],
      optionStores: [],
      optionOrigin: {
        employees: [],
        forms: [],
      },
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
      filteredStatus: [],
      optionStatus: [],
      filteredForm: [],
      filteredStore: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Multiselect,
    EditPreOrder,
    DatePicker,
    PreOrderExcel,
  },
  computed: {
    optionEmployees() {
      return [{ data: cloneDeep(this.filteredEmployees) }];
    },
  },
  mounted() {
    this.title = 'Danh sách đơn hàng đặt trước';
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  created() {
    this.onInitData();
    this.onFilter();
    this.fetchStores();
    this.fetchEmployees();
    this.fetchPreOrdersForm();
  },
  methods: {
    formatDate,
    convertPrice,
    onInitData() {
      this.paymentStatus = cloneDeep(PAYMENT_STATUS);
      this.optionsWantUpgrade = cloneDeep(CONSTANT_WANT_UPGRADE);

      const status = cloneDeep(
        PREORDER_STATUS.filter((x) => x.checked === false)
      );
      this.optionStatus = [
        {
          id: -1,
          name: 'Tất cả trạng thái',
          checked: false,
        },
        ...status,
      ];
      this.filteredStatus = cloneDeep(this.optionStatus);
    },
    debounceInput: debounce((textInput, fn) => {
      fn(textInput);
    }, TIME_TRIGGER),
    formatProductName(data) {
      return [data.productName, data.size, data.color]
        .filter((item) => item)
        .join(' - ');
    },
    onInputed(textInput = '', type) {
      switch (type) {
        case 'Form':
          this.searchForm(textInput);
          break;
        case 'Store':
          this.searchStore(textInput);
          break;
        case 'Employee': {
          if (!textInput || !textInput.trim().length) {
            this.apiParams.createdByName = null;
            this.apiParams.createdBy = null;
          }
          this.debounceInput(textInput, this.searchEmployeeAPI);
          break;
        }
        case 'Status': {
          this.searchStatus(textInput);
          break;
        }

        default:
          break;
      }
    },
    onSelected(option, type) {
      switch (type) {
        case 'Form': {
          const { items, selectItems } = selectAllOptions(
            this.optionForms,
            this.apiParams.formIds,
            option
          );
          this.filteredForm = items;
          this.apiParams.formIds = selectItems;
          break;
        }
        case 'Employee':
          this.apiParams.createdBy = option.item.id;
          break;
        case 'Store': {
          const { items, selectItems } = selectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option
          );
          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }
        case 'Status': {
          const { items, selectItems } = selectAllOptions(
            this.optionStatus,
            this.apiParams.status,
            option
          );

          this.filteredStatus = items;
          this.apiParams.status = selectItems;
          break;
        }

        default:
          break;
      }
    },
    onRemoved(option, type) {
      switch (type) {
        case 'Form': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionForms,
            this.apiParams.formIds,
            option
          );

          this.filteredForm = items;
          this.apiParams.formIds = selectItems;
          break;
        }

        case 'Store': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionStores,
            this.apiParams.storeIds,
            option
          );

          this.filteredStore = items;
          this.apiParams.storeIds = selectItems;
          break;
        }
        case 'Status': {
          const { items, selectItems } = removeSelectAllOptions(
            this.optionStatus,
            this.apiParams.status,
            option
          );

          this.filteredStatus = items;
          this.apiParams.status = selectItems;
          break;
        }
        default:
          break;
      }
    },
    filterByFormId(formId) {
      const exist = this.optionForms.find((prop) => prop.id === formId);

      if (!exist) {
        this.fetchPreOrdersFormById(formId).then((response) => {
          if (response.status) {
            const form = response.data.data[0];
            const option = {
              id: form.id,
              name: form.name,
              checked: true,
            };
            this.apiParams.formIds = [];
            this.apiParams.formIds.push(option);
            this.optionForms.push(option);
            this.filteredForm.push(option);
            this.onFilter();
          }
        });
      } else {
        const option = {
          id: exist.id,
          name: exist.name,
          checked: true,
        };
        this.apiParams.formIds = [];
        this.apiParams.formIds.push(option);

        this.filteredForm.map((item) => {
          item.checked = item.id === option.id ? true : false;
          return item;
        });

        this.onFilter();
      }
    },
    getStatusName(id) {
      const preOrderStatus = PREORDER_STATUS.filter((status) => status.id);
      const picked = keyBy(preOrderStatus, 'id')[id];
      return picked ? picked.name : '';
    },
    downloadExcel() {
      this.$bvModal.show('modal-pre-order-excel');
    },
    editPreOrder(id) {
      const preOrder = this.preOrders.find((x) => x.id === id);

      this.preOrder = preOrder;
      this.preOrder.orderId = preOrder.orderId;
      this.preOrder.orderWarning = preOrder.orderWarning;
      
      const products = [
        {
          productName: preOrder.productName,
          productPrice: preOrder.productPrice,
          size: preOrder.size,
          color: preOrder.color,
        },
      ];

      this.preOrder.products = products;
      this.$bvModal.show('editPreOrder-pre-order');
    },
    onFilter() {
      this.onRefreshPageOne();
      this.fetchPreOrders();
    },
    onRefreshPageOne() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-pre-order',
      });
    },
    onRefresh() {
      this.fetchPreOrders();
    },
    getValuesMultiSelect(arr, prop) {
      return arr.reduce((accumulator, currentValue) => {
        if (currentValue.checked) accumulator.push(currentValue[prop]);
        return accumulator;
      }, []);
    },
    editUser: function (item) {
      this.$router.push({
        name: 'update-customer',
        query: { id: item.customerId, phoneNo: item.customerMobile },
      });
    },
    getParamFilters() {
      // //get danh sách status filter
      const DATE_FORMAT = 'DD/MM/YYYY';
      const DATE_DB_FORMAT = 'YYYY-MM-DD';
      const apiParams = cloneDeep(this.apiParams);

      apiParams.status = apiParams.status.map((item) => item.id);
      if (apiParams.status.includes(-1)) {
        apiParams.status = [];
      }
      apiParams.storeIds = apiParams.storeIds.map((item) => item.id);
      if (apiParams.storeIds.includes(-1)) {
        apiParams.storeIds = [];
      }
      apiParams.formIds = apiParams.formIds.map((item) => item.id);
      if (apiParams.formIds.includes(-1)) {
        apiParams.formIds = [];
      }

      return {
        page: this.$route.query.page || 1,
        size: this.size || 10,
        ...apiParams,
        fromDate: this.apiParams.fromDate
          ? moment(apiParams.fromDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
        toDate: this.apiParams.toDate
          ? moment(apiParams.toDate, DATE_FORMAT).format(DATE_DB_FORMAT)
          : null,
      };
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionStores);
      if (!textInput || !textInput.trim().length) {
        this.filteredStore = map(options, (obj) => {
          return assign(obj, find(this.filteredStore, { id: obj.id }));
        });
        // this.filteredStatus = options
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStore, { id: obj.id }));
      });

      this.filteredStore = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    searchForm(textInput) {
      let options = cloneDeep(this.optionForms);
      if (!textInput || !textInput.trim().length) {
        this.filteredForm = map(options, (obj) => {
          return assign(obj, find(this.filteredForm, { id: obj.id }));
        });
        // this.filteredStatus = options
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredForm, { id: obj.id }));
      });

      this.filteredForm = this.fitlerOptionsBy(options, textInput, 'name', 10);
    },
    searchStatus(textInput) {
      let options = cloneDeep(this.optionStatus);
      if (!textInput || !textInput.trim().length) {
        this.filteredStatus = map(options, (obj) => {
          return assign(obj, find(this.filteredStatus, { id: obj.id }));
        });
        // this.filteredStatus = options
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredStatus, { id: obj.id }));
      });

      this.filteredStatus = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10
      );
    },
    searchEmployeeAPI(textInput) {
      if (!textInput || !textInput.trim().length) {
        return;
      }

      this.filteredEmployees = this.fitlerOptionsBy(
        this.optionOrigin.employees,
        textInput,
        'fullName',
        10
      );
    },
    searchFormAPI(textInput) {
      if (!textInput || !textInput.trim().length) {
        return;
      }

      this.optionForms = this.fitlerOptionsBy(
        this.optionOrigin.forms,
        textInput,
        'name',
        10
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    fetchPreOrders: function () {
      if (!this.onLoading) {
        this.onLoading = true;
        this.preOrders = [];
        const params = this.getParamFilters();

        ApiService.query('pre-orders', { params }, { timeout: TIME_OUT })
          .then((response) => {
            const dataset = response.data.data;
            this.totalItem = dataset.total;
            this.numberOfPage = dataset.total_page;
            this.preOrders = dataset.data;
            this.onLoading = false;
          })
          .catch((error) => {
            this.onLoading = false;
            this.$nprogress.done();
            if (error.response) {
              makeToastFaile(
                error.response.data ? error.response.data.message : 'Lỗi'
              );
            } else {
              makeToastFaile(error.message || 'Lỗi');
            }
          });
      }
    },
    fetchPreOrdersFormById: function (formId) {
      return ApiService.query('pre-orders/form', {
        params: { formId: formId, page: 1, size: 1 },
      }).then((response) => response.data);
    },
    fetchPreOrdersForm: function () {
      ApiService.query('pre-orders/form', {
        params: { size: 10000, page: PAGE },
      }).then((response) => {
        const dataset = response.data.data;
        const forms = [
          {
            id: -1,
            name: 'Tất cả chương trình',
            checked: false,
          },
          ...dataset.data,
        ];
        this.optionForms = forms;
        this.filteredForm = cloneDeep(forms);
        this.optionOrigin.forms = cloneDeep(forms);
      });
    },
    fetchStores: async function () {
      const DEFAULT_COMPANY_ID = 3;
      ApiService.query(`stores/getStores`, {
        params: {
          companyId: DEFAULT_COMPANY_ID,
        },
      }).then((response) => {
        const stores = response.data.data;
        this.optionStores = [
          {
            id: -1,
            name: 'Tất cả cửa hàng',
            checked: false,
          },
          ...stores,
        ];
        this.filteredStore = cloneDeep(this.optionStores);
      });
    },
    fetchEmployees: async function () {
      ApiService.get('employees/getAll').then((response) => {
        const employees = response.data.data;
        this.filteredEmployees = employees;
        this.optionOrigin.employees = employees;
      });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
  cursor: pointer;
}
.cursor-pointer:hover {
  text-decoration: underline;
}
input.form-control {
  border: 1px solid #ced4da;
}

.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}

.autosuggest__results {
  position: absolute;
  width: 100%;
  z-index: 1;
  max-height: 200px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.pre-order {
  .vue-treeselect__multi-value-item-container {
    display: none;
  }
  // .vue-treeselect__multi-value-item-container:nth-child(1) {
  //   display: block;
  // }

  .vue-treeselect__input-container {
    padding-top: 0px;
  }

  .vue-treeselect__control {
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    height: 2.5rem;
  }

  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 2px;
  }

  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .table thead tr > th {
    text-align: center;
  }

  // .multiselect__checkbox {
  //   position: absolute;
  //   // right: 0.3vw;
  //   right: 0.3vw;
  // }

  // .multiselect__checkbox [type='checkbox'][readonly='readonly']::before {
  //   content: '';
  // }
  .multiselect__checkbox_name {
    display: block;
    overflow: hidden;
  }

  .multiselect__checkbox {
    vertical-align: middle;
    float: right;
  }
  .multiselect__tags {
    height: calc(1.35em + 1.1rem + 2px);
    display: block;
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    font-size: 0.925rem;
    min-height: initial;
    padding-top: 0.3rem;
  }

  .multiselect__option {
    padding: 5px;
    padding-top: 8px;
    min-height: 30px;
  }

  .multiselect__select {
    width: 3rem;
    height: 2.5rem;
    top: 0.1rem;
  }

  .multiselect__placeholder {
    color: #495057;
    padding-left: 0.2rem;
  }

  .multiselect__element {
    font-size: 0.925rem;
  }

  .multiselect__input {
    padding-top: 0.2rem;
    font-size: 0.925rem;
  }

  .multiselect__single {
    font-size: 0.925rem;
    line-height: 1.9;
    padding-left: 0.2rem;
    display: inline-block;
    max-width: 100%;
  }

  .dashed {
    border: 1px dashed #464e5f;
    border-style: none none dashed;
    color: #fff;
    background-color: #fff;
  }
  .pre-line {
    white-space: pre-line;
  }
}

</style>
